import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  type ReactNode,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { isRootLevelPage } from '../page-utils';

interface NextPageContextProps {
  setNextPageContext: (value: string | null) => void;
  enterAnimation: string;
  exitAnimation: string;
}

const NextPageContext = createContext<NextPageContextProps | undefined>(
  undefined
);

export const useNextPage = (): NextPageContextProps => {
  const context = useContext(NextPageContext);
  if (!context) {
    throw new Error('useNextPage must be used within a NextPageProvider');
  }
  return context;
};

interface NextPageProviderProps {
  children: ReactNode;
}

export const NextPageProvider: React.FC<NextPageProviderProps> = ({
  children,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [currentPage, setCurrenctPage] = useState(location.pathname);
  const [exitAnimation, setExitAnimation] = useState('');
  const [enterAnimation, setEnterAnimation] = useState('');

  const isOtherRootLevelPage = (value: string | null): boolean =>
    ['/settings', '/reports', '/messages'].includes(value ?? '');

  const setMainPageAnimation = (): void => {
    setExitAnimation('fade-down');
    setEnterAnimation('fade-up');
  };

  const setBackAnimation = (): void => {
    setExitAnimation('to-right');
    setEnterAnimation('from-left');
  };

  const setForwardAnimation = (): void => {
    setExitAnimation('to-left');
    setEnterAnimation('from-right');
  };

  useEffect(() => {
    const handlePop = (): void => {
      if (isOtherRootLevelPage(currentPage)) {
        setMainPageAnimation();
      } else {
        setBackAnimation();
      }
      setCurrenctPage(location.pathname);
    };
    window.addEventListener('popstate', handlePop);
    return () => {
      window.removeEventListener('popstate', handlePop);
    };
  }, [currentPage]);

  const setNextPageContext = (value: string | null): void => {
    if (value !== null && isRootLevelPage(value)) {
      setMainPageAnimation();
      const currentPage = location.pathname;
      if (isOtherRootLevelPage(currentPage) && ['/', '/home'].includes(value)) {
        navigate(-1);
        return;
      } else if (
        isOtherRootLevelPage(currentPage) &&
        isOtherRootLevelPage(value)
      ) {
        navigate(value, { replace: true });
        return;
      }
    } else {
      setForwardAnimation();
    }
    navigate(value ?? '/', { state: { from: currentPage } });
    setCurrenctPage(value ?? '/');
  };

  return (
    <NextPageContext.Provider
      value={{ setNextPageContext, enterAnimation, exitAnimation }}
    >
      {children}
    </NextPageContext.Provider>
  );
};
